import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import config from '~/../site-config.js'

interface Props {
  title?: string
  description?: string | null
  canonical?: string | null
}

const Metadata: React.FC<Props> = ({
  title: metaTitle = config.siteMetadata.title,
  description = 'Why risk it? Know before you buy. Avoid buying a home with costly hidden problems and repairs by putting it under the PropertyLens!',
  canonical,
}) => {
  const { asPath } = useRouter()
  const metaUrl = new URL(asPath, config.siteMetadata.siteUrl).toString()
  const metaImage = config.siteMetadata.image
  const metaDescription = description
    ? description
    : `${metaTitle} | PropertyLens`

  if (canonical?.startsWith('/')) {
    canonical = new URL(canonical, config.siteMetadata.siteUrl).toString()
  }

  return (
    <Head>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:site_name" content={metaTitle} />
      <meta property="og:image" content={metaImage} />

      <meta property="og:url" content={metaUrl} />
      <meta property="twitter:url" content={metaUrl} />

      <meta property="twitter:card" content="summary" />
      <meta property="twitter:title" content={metaTitle} />
      <meta property="twitter:description" content={metaDescription} />

      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="/favicon/apple-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="/favicon/apple-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="/favicon/apple-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="/favicon/apple-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/favicon/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/favicon/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/favicon/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/favicon/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/favicon/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />

      {canonical && <link rel="canonical" href={canonical} />}
    </Head>
  )
}

export default Metadata
