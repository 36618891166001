/** @jsxImportSource theme-ui */
import React from 'react'
import { Box, Container, Heading, Text } from 'theme-ui'
import { useTranslate } from '~/hooks/use-translate'

interface Props {
  title?: string
}

const ErrorNotFoundPage: React.FC<Props> = ({ title }) => {
  const translate = useTranslate()

  return (
    <Box>
      <Container>
        <Box
          sx={{
            padding: ['3rem 0', '8rem 0'],
            textAlign: 'center',
          }}
        >
          <Heading as="h1" variant="h1" sx={{ mb: 2 }}>
            {title ?? translate('error_not_found_title')}
          </Heading>
          <Text>{translate('error_not_found_description')}</Text>
        </Box>
      </Container>
    </Box>
  )
}

export default ErrorNotFoundPage
